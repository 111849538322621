













































import { Component, Prop, Vue } from 'vue-property-decorator'
import Badge from '@/app/ui/components/Badge/index.vue'
import IconClock from '@/app/ui/assets/icon_clock.vue'
import IconCheckCircle from '@/app/ui/assets/icon_check_circle_thin.vue'
import IconCloseLine from '@/app/ui/assets/close_circle.vue'
import dayjs from 'dayjs'
import { AdjustmentStatus } from '@/app/infrastructures/misc'

@Component({
  components: {
    Badge,
    IconClock,
    IconCheckCircle,
    IconCloseLine,
  },
})
export default class AdjustmentNotification extends Vue {
  @Prop({ type: String, default: 'warning' }) private type!: string
  @Prop({ type: String, default: '' }) private approval!: string
  @Prop({ type: String, default: '' }) private history!: string

  AdjustmentStatus = AdjustmentStatus

  private getHistory(): string {
    return dayjs(this.history)
      .format('MMMM D, YYYY, h.mm')
  }

  private getType(): string {
    if (this.type.toUpperCase() === AdjustmentStatus.REQUESTED) {
      return 'warning'
    } else if (this.type.toUpperCase() === AdjustmentStatus.APPROVED) {
      return 'success'
    } else if (this.type.toUpperCase() === AdjustmentStatus.REJECTED) {
      return 'error'
    }
    return 'warning'
  }
}
