







































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AdjustmentTextInput from '../../components/AdjustmentTextInput/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import AdjustmentNotification from '../../components/AdjustmentNoftification/index.vue'
import ModalDetail from '../../components/AdjustmentModals/ModalDetail/index.vue'
import {
  Utils,
  ALL_DATA_ADJUSTMENT_PAGINATION,
  HEADER_REQUEST_TOPUP_HISTORY,
} from '@/app/infrastructures/misc'
import controller, {
  RequestTopupListParams,
} from '@/app/ui/controllers/RequestTopupSaldoController'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import { Dictionary } from 'vue-router/types/router'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import * as constantsData from '@/app/infrastructures/misc/Constants/adjustment'

@Component({
  components: {
    RadioButton,
    AdjustmentTextInput,
    LoadingOverlay,
    DataTableV2,
    PaginationNav,
    AdjustmentNotification,
    ModalDetail,
  },
})
export default class AllDataAdjustmentDetailPage extends Vue {
  controller = controller
  constantsData = constantsData
  form = {
    saldo: NaN,
    balanceTypeAdjustment: constantsData.EnumBalanceID.TOP_UP,
    notes: '',
  }
  parameters = {
    sortBy: 'NEWEST',
    filterStatus: 'SHOWALL',
    page: 1,
    perPage: ALL_DATA_ADJUSTMENT_PAGINATION,
    customerId: 0,
  }
  notification = {
    actor: '',
    historyCreatedAt: '',
    status: '',
  }
  currentNotes = ''
  currencySaldoSymbol = ''
  headers = HEADER_REQUEST_TOPUP_HISTORY
  modalDetail = false

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page || 1),
        perPage: ALL_DATA_ADJUSTMENT_PAGINATION,
        sortBy: 'NEWEST',
        filterStatus: 'REQUESTED',
        customerId: 0,
      }
    }

    this.fetchHistory()
    this.fetchDetail()
  }

  get params(): RequestTopupListParams {
    return {
      ...this.parameters,
      customerId: Number(this.$route.params.id.split('-')[0]),
    }
  }

  get phoneNumber(): string {
    return this.controller.phoneNumber
      ? Utils.countryIndonesiaPhoneNumber(this.controller.phoneNumber, true)
      : ''
  }

  get dataHistoryUser(): Array<DataObject[]> {
    return this.controller.listRequestTopupHistory.map(item => {
      return [
        {
          value: item?.createdAt
            ? Utils.formatTimeZone(
                Utils.formatDateWithIDLocale(
                  item.createdAt,
                  'DD MMMM YYYY HH:mm Z'
                )
              )
            : '-',
          customStyle: { minWidth: '220px', maxWidth: '220px' },
        },
        {
          value: `${
            this.getBalanceTypeAdjustment(item.balanceTypeID || '')
              .symbol
          }${Utils.toRupiah(Math.abs(item?.totalSaldoRequest || 0))}`,
          customStyle: { minWidth: '125px', maxWidth: '125px' },
        },
        {
          value: Utils.toRupiah(item?.currentBalance || 0),
          customStyle: { minWidth: '140px', maxWidth: '140px' },
        },
        {
          value: item?.requestor || '',
          customStyle: { minWidth: '140px', maxWidth: '140px' },
        },
        {
          value: item?.approval || '',
          customStyle: { minWidth: '140px', maxWidth: '140px' },
        },
        {
          value: item?.note || '',
          customStyle: { minWidth: '155px', maxWidth: '155px' },
        },
        {
          value: item?.balanceStatusId || '',
          customStyle: { minWidth: '110px', maxWidth: '110px' },
        },
      ]
    })
  }

  private fetchHistory(): void {
    this.controller.getHistoryRequestTop(this.params)
  }

  private fetchDetail(): void {
    this.controller.getDetailTopupForm(
      Number(this.$route.params.id.split('-')[1])
    )
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router
      .replace({
        query: val,
      })
      .catch(() => false)
  }

  private getBalanceTypeAdjustment(
    balanceTypeAdjustment: string
  ): {
    symbol: string
    type: constantsData.EnumBalanceID
  } {
    let data = { symbol: '+', type: constantsData.EnumBalanceID.TOP_UP }
    if (
      balanceTypeAdjustment.toUpperCase() ===
      constantsData.EnumBalanceID.DEDUCT
    ) {
      data = { symbol: '-', type: constantsData.EnumBalanceID.DEDUCT }
    }
    return data
  }

  @Watch('controller.detailTopupForm')
  onChangeForm(): void {
    const balanceTypeAdjustment = this.getBalanceTypeAdjustment(
      controller.detailTopupForm?.balanceTypeId || ''
    )

    this.form = {
      saldo: this.controller.detailTopupForm?.amountRequested || 0,
      balanceTypeAdjustment: balanceTypeAdjustment.type,
      notes: this.controller.detailTopupForm?.note || '',
    }

    this.notification = {
      actor: this.controller.detailTopupForm?.actor || '',
      historyCreatedAt: this.controller.detailTopupForm.historyCreadtedAt || '',
      status: Utils.toCapitalize(
        this.controller.detailTopupForm.balanceStatusId || ''
      ),
    }
  }

  private seeNotes(index: number) {
    this.modalDetail = !this.modalDetail
    this.currentNotes = <string>this.dataHistoryUser[index][5].value
  }
}
